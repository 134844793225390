<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>Formula Médica - Medicamentos</page-header-compact>
        <div class="container-xl px-4 mt-n15">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2" v-for="item in formula.items" :key="item.id">
                <div class="card-body" >
                    <h6>Codigo: <span class="text-danger">{{item.servicio.codigo}}</span></h6>
                    <h6>Medicamento: <span class="text-primary">{{item.servicio.descripcion}}</span></h6>
                    <h6>Posologia: {{ item.dosis }} cada {{ item.frecuencia }}
                        {{ item.unidad_frecuencia }} Por {{ item.duracion }} días</h6>
                    <h6>Total: <span class="badge badge-secondary badge-marketing">{{item.total}}</span></h6>     
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import usuarioService from '../../../../services/usuarioService';
import formulaMedicaService from '../../../../services/formulaMedicaService';
import Toast from '../../../../components/common/utilities/toast';

export default {
    components: {modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact},
    data(){
        return {
            usuario: {},
            formula:{}
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarFormula(id){
            const response = await formulaMedicaService.showFormulaById(id);
            this.formula= response.data;
        },
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            await this.cargarUsuario(this.$route.params.id_usuario);
            await this.cargarFormula(this.$route.params.id_formula);
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
        
    }
}
</script>